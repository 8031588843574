.back-top {
    position: fixed;
    bottom: 47px;
    right: 50px;
    height: 50px;
    width: 50px;
    font-size: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    background-color: #E84E1B;
    border: none;
    color: white;
    transition: .3s;
    border-radius: 5px;
    z-index: 9999;
}


.back-top:hover {
    opacity: .5;
}