.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  margin: 0
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace
}

.back-top {
  align-items: center;
  background-color: #662d91;
  border: none;
  border-radius: 5px;
  bottom: 47px;
  color: #fff;
  display: flex;
  font-size: 50px;
  height: 50px;
  position: fixed;
  right: 50px;
  text-align: center;
  transition: .3s;
  width: 50px;
  z-index: 9999
}

.back-top:hover {
  opacity: .5
}

/*
Theme Name: elfooz
Theme URI: elfooz.com
Author: 
Author URI: 
Version: 1.0
*/


/**
 * Globel Style Start
 */

@font-face {
  font-family: 'Styrene B', sans-serif;
  src: url(//db.onlinewebfonts.com/c/5d86f2e8ccc2811b9392aa03b7ce4a63?family=Styrene+B+Regular);
}

:root {
  /*Font Size*/
  --fontsize-89px: 4.684vw;
  --fontsize-57px: 57px;
  --fontsize-28px: 28px;
  --fontsize-24px: 24px;
  --fontsize-22px: 22px;
  --fontsize-20px: 20px;
  --fontsize-18px: 18px;
  --fontsize-16px: 16px;
  /*color code var*/
  --color-white: #ffffff;
  --color-dark-blue: #11131F;
  --color-ghost: #C9CFD9;
  --color-bright-turquoise: #1BDCE9;
  --color-Manatee: #868D9A;
  --color-loblolly: #BFC4CC;
  --color-Loblolly: #BFC4CC;
  --color-Loblolly: #BFC4CC;
  /*Font Family*/
  --Styrene-b-font: 'Styrene B', sans-serif;
}

html,
html body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

*,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--Styrene-b-font);
  font-size: var(--fontsize-22px);
  line-height: 1.6;
  font-weight: 400;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  text-decoration: none;
}

a:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  font-family: var(--Styrene-b-font);
}

h1 {
  font-size: var(--fontsize-89px);
  line-height: 1.2;
}

.sub-title {
  font-size: var(--fontsize-24px);
  line-height: 1.5;
  color: var(--color-white);
  text-transform: uppercase;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(249, 249, 249, 0.2);
  backdrop-filter: blur(15px);
  border-radius: 5px;
  padding: 16px 40px;
  display: inline-block;
}

.section-content h1 span,
.section-content h2 span {
  font-size: var(--fontsize-57px);
}

.section-content h1,
.section-content h2 {
  font-size: var(--fontsize-89px);
  color: var(--color-white);
  margin-bottom: 30px;
}

.section-content p {
  text-align: justify;
  margin-bottom: 10px !important;
  /* padding-left: 6% !important; */
  color: var(--color-ghost);
}

.section-content p a {
  text-decoration: underline;
  font-size: var(--fontsize-24px);
  font-weight: 700;
  color: var(--color-bright-turquoise);
}

.section-content p a:hover {
  opacity: .5;
}

img {
  width: 100%;
  height: auto;
}

.container.xxl,
.container.xl,
.container {
  width: 90%;
  max-width: 1719px;
  margin: 0 auto;
  padding-right: 0.9625rem;
  padding-left: 0.9375rem;
}

.container.xl {
  max-width: 1580px;
}

.container {
  max-width: 1460px;
}

p {
  margin: 0 !important;
  padding: 0 !important;
}


/* custom scroll bar  */

::-webkit-scrollbar {
  width: 8px;
}

div::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background: #662d91;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 8px #662d91;
}

.bg-cover {
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  background-color: var(--color-EBE1E1);
}

#back_to_top {
  display: inline-block;
  background-color: #662d91;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  position: fixed;
  font-size: var(--fontsize-24px);
  color: var(--color-white);
  bottom: 30px;
  right: 30px;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  border-radius: 5px;
}

#back_to_top:hover {
  cursor: pointer;
  color: var(--color-171517);
}

#back_to_top:active {
  opacity: 0.5;
}

#back_to_top.show {
  opacity: 1;
  visibility: visible;
}

.owl-nav .owl-prev,
.owl-nav .owl-next {
  position: absolute;
  top: 50%;
  left: -80px;
  margin-top: -20px;
}

.owl-nav .owl-next {
  left: auto;
  right: -80px;
}

.owl-nav img {
  max-width: 60px;
}

.owl-nav img:hover {
  opacity: .5;
}

.dark-bg-area {
  background-color: #11131F;
  z-index: 999;
  padding: 120px 0;
  position: relative;
}

.guests_from_afar_content {
  padding-bottom: 184px;
}

.rectangle {
  position: relative;
  z-index: 1;
}

.rectangle:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 181px;
  background: linear-gradient(180deg, #11131F 0%, rgba(213, 213, 213, 0) 100%);
  z-index: -1;
}

.rectangle:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 414px;
  background: linear-gradient(180deg, rgba(220, 215, 215, 0) -.63%, #000000 100%);
  z-index: -1;
}

.overlay {
  position: relative;
  z-index: 1;
}

.overlay:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.1);
}

.section-desc {
  padding: 0 15%;
}

.bg-black {
  background-color: #050707 !important;
}

/**
* # Globle Style End
* --------------------------------------------- */


/**
* # Header Style Start
* --------------------------------------------- */

.navbar_top {
  /* background: transparent; */
  background-color: #11131F;
}

.navbar_top.show {
  background-color: #11131F;
}

.navbar_top.show .navbar-toggler-icon::before,
.navbar_top.show .navbar-toggler-icon::after {
  background-color: white;
}

.fixed-top {
  top: -300px;
  transform: translateY(300px);
  transition: transform 1s;
  -webkit-transform: translateY(300px);
  -moz-transform: translateY(300px);
  -ms-transform: translateY(300px);
  -o-transform: translateY(300px);
  -webkit-transition: transform 1s;
  -moz-transition: transform 1s;
  -ms-transition: transform 1s;
  -o-transition: transform 1s;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  background-color: #11131F;
  padding: 10px 0;
}

.navbar_top {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  /* padding-top: 20px; */
  padding: 10px 0;
}

.header-area .container {
  position: relative;
}

.site-logo img {
  max-width: 325px;
}

.navbar_top .navbar ul li a {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.6;
  color: #fff;
  display: inline-block;
  padding-right: 35px;
}

.fixed-top .navbar ul li a {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.6;
  color: #fff;
  display: inline-block;
  padding-right: 35px;
}

.navbar_top .navbar ul li a:hover {
  opacity: .5;
}

.fixed-top .navbar ul li a:hover {
  opacity: .5;
}

.navbar_top .boxed-btn {
  min-width: 200px;
  background: #662d91;
  border-radius: 5px;
  color: #fff;
  justify-content: center;
  gap: 15px;
  padding: 10px 15px;
  line-height: 1.7;
  padding-bottom: 15px;
  margin-right: 15px;
  font-size: 20px;
}

.fixed-top .boxed-btn {
  min-width: 200px;
  background: #662d91;
  border-radius: 5px;
  color: #fff;
  justify-content: center;
  gap: 15px;
  padding: 10px 15px;
  line-height: 1.7;
  padding-bottom: 15px;
  margin-right: 15px;
  font-size: 20px;
}

.navbar_top .boxed-btn img {
  max-width: 28px;
}

.fixed-top .boxed-btn img {
  max-width: 28px;
}

.navbar_top .boxed-btn:hover {
  color: var(--color-bright-turquoise);
}

.fixed-top .boxed-btn:hover {
  color: var(--color-bright-turquoise);
}

.header-right {
  width: 58%;
  display: flex !important;
  justify-content: space-between;
}

.header-left {
  width: 58%;
}

.header-right .social-icons a:last-child {
  margin-left: 10px;
}


/**
* # Hero-area
* --------------------------------------------- */

.hero-area {
  position: relative;
  height: 1019px;
  z-index: 1;
  padding-bottom: 176px;
}

.hero-area:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 245px;
  background: linear-gradient(180deg, rgba(147, 148, 155, 0) 0%, #11131F 100%);
  z-index: -1;
}

.hero-area div {
  height: 100%;
}

.scroll-down-btn img {
  max-width: 60px;
}

.scroll-down-btn {
  position: absolute;
  left: 50%;
  bottom: 176px;
  transform: translateX(-50%);
}

.scroll-down-btn {
  animation: jumpInfinite 1.5s infinite;
}

@keyframes jumpInfinite {
  0% {
    bottom: 176px;
  }

  50% {
    bottom: 160px;
  }

  100% {
    bottom: 176px;
  }
}


/**
* # strange-new-world-area Style
* 
* ---------------------------------------------------------------- */

.strange-new-world-area {
  padding: 186px 0;
  background-color: #11131F;
  color: #C9CFD9;
}

.strange-new-world-area .section-content p {
  padding: 0 10% !important;
}


/**
* # guests-from-afar Style
* 
* ---------------------------------------------------------------- */

.guests-from-afar-area {
  height: 1019px;
}

.guests_from_afar_content .section-content,
.build_a_metaverse_content_area .section-content {
  margin-top: -350px;
}

.build-a-metaverse-area .section-content {
  margin-bottom: -140px;
}

.guests_from_afar_content .section-desc {
  max-width: 930px;
  margin: 0 auto;
  padding: 0;
  text-align: justify;
}

.guests-from-afar-area.rectangle::after {
  background: linear-gradient(180deg, rgba(213, 213, 213, 0) 24.15%, #11131F 100%);
}

/**
* # build-a-metaverse-area Start style
* 
* ---------------------------------------------------------------- */

.build-a-metaverse-area {
  height: 1080px;
}

.build-a-metaverse-area.rectangle:before {
  background: linear-gradient(180deg, #11131F 0%, rgba(24, 44, 53, 0) 100%);
}

.build-a-metaverse-inner.overlay:after {
  background: rgba(0, 0, 0, 0);
}

.build-a-metaverse-area.rectangle:after {
  height: 296px;
  background: linear-gradient(180deg, rgba(43, 45, 54, 0.03) 0%, #11131F 100%);
}

.build_a_metaverse_content_area.section-desc {
  max-width: 930px;
  margin: 0 auto;
  padding: 0 !important;
}

.build_a_metaverse_content_area.section-desc p {
  padding: 0 5% !important;
}


/**
* # assembled style Start 
* ---------------------------------------------------------------- */

.assembled-area .section-content h2 span {
  font-size: var(--fontsize-89px);
}

.assembled-area .section-content p {
  color: var(--color-Manatee);
  padding: 0 15% !important
}

.single-assembled-item {
  text-align: center;
  margin-bottom: 30px;
}

.single-assembled-item h5 {
  font-weight: 700;
  font-size: var(--fontsize-28px);
  text-transform: capitalize;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: var(--color-loblolly);
}

.assembled-service-box-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  grid-gap: 30px;
}

.assemble-logo-box {
  height: 190px;
  width: 140px;
  margin: 0 auto;
}

@media(max-width:992px) {
  .assembled-service-box-wrapper {
    grid-gap: 15px;
  }

  .assemble-logo-box {
    height: 120px;
    width: 110px;
  }

  .single-assembled-item h5 {
    font-size: 17px !important;
  }
}


/**
* # elfooz-team style Start 
* ---------------------------------------------------------------- */

.elfooz-team-area {
  padding-top: 85px;
}

.client-avater img {
  max-width: 270px;
  margin: 0 auto;
}

.client-avater {
  text-align: center;
  background: rgba(102, 77, 74, 0.09);
  border: 1px solid rgba(235, 238, 243, 0.1);
  border-radius: 10px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: all .3s ease;
}

.team-social-box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(174.42deg, #C45E15 0%, #0F98AB 100%);
  border-radius: 0px 33.5px 33.5px 0px;
  padding: 34px 13px;
  left: -30px;
  transition: all .3s ease;
}

.team-social-box i {
  color: #fff;
  font-size: 15px;
  transition: .3s;
}

.team-social-box i:hover {
  opacity: .5;
}

.client-avater:hover .team-social-box {
  left: 0;
}

.client-avater:hover {
  background-color: #1F1716;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to right, transparent, white) 10;
  border-radius: 1px;
}

.team-desc {
  padding-top: 40px;
  color: #fff;
  text-align: center;
}

.team-desc h4 {
  font-size: 28px;
  color: #BFC4CC;
  line-height: 1.4;
  margin-bottom: 10px;
}

.client-descnation {
  font-size: 16px;
  line-height: 1.6;
  color: #868D9A;
}

.client-descnation span {
  display: block;
}

.client-link {
  color: #fff;
  margin-top: 8px;
  display: inline-block;
  text-align: center;
}

.client-link:hover {
  color: #fff;
  opacity: .75;
}

.frequently-ask-q-area {
  padding: 176px 0;
}

.discord,
.twitter,
.paper-plane,
.reddit,
.instagram {
  background: #5865F2;
  width: 50px;
  height: 50px;
  display: inline-block;
  color: #fff;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
}

.discord:hover,
.twitter:hover,
.paper-plane:hover,
.reddit:hover,
.instagram:hover {
  color: var(--color-ghost);
}

.twitter {
  background-color: #55ACEE;
}

.paper-plane {
  background: linear-gradient(180deg, #2AABEE 0%, #229ED9 100%);
}

.reddit {
  background: #FF4500;
}

.instagram {
  background: radial-gradient(179.06% 43.44% at -16.75% 7.2%, #3771C8 0%, #3771C8 12.8%, rgba(102, 0, 255, 0) 100%);
  position: relative;
  z-index: 1;
}

.instagram::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(92.18% 99.11% at 26.56% 107.7%, #FFDD55 0%, #FFDD55 10%, #FF543E 50%, #C837AB 100%);
  z-index: -1;
  border-radius: 50%;
}


/**
* # FAQ Start style
* 
* ---------------------------------------------------------------- */

.faq-accordion-header {
  margin: 0;
}

.faq-accordion-header button {
  padding: 20px;
  background: #05090D;
  font-weight: 700;
  font-size: 22px;
  color: #BFC4CC;
  line-height: 150%;
  box-sizing: border-box;
  border: 1.5px solid rgba(245, 249, 255, 0.1);
  border-radius: 10px;
}

.accordion-button:not(.collapsed) {
  color: #BFC4CC;
  background: #05090D;
  box-shadow: inherit;
}

.faq.accordion-item {
  background: #05090D;
  border: 1.5px solid rgba(245, 249, 255, 0.1);
  border-radius: 10px;
}

.faq.accordion-item {
  margin-bottom: 17px;
}

.faq-accordion-header .accordion-button::after {
  background-image: url(./assets/img/plus.svg);
}

.accordion-button:not(.collapsed)::after {
  background-image: url(./assets/img/minus.svg);
}

.faq-accordion-header button::after {
  background-size: 16px;
  width: 2.25rem;
}

.accordion-body p {
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #B8BDC5;
}

.accordion-body {
  padding: 12px 20px
}

.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, .125);
}

.accordion-button:not(.collapsed)::after {
  transform: rotate(0);
  transition: all .3s ease-in-out;
}

.frequently-ask-q-area .section-content,
.elfooz-team-area .section-content {
  max-width: 732px;
  margin: 0 auto;
}

.frequently-ask-q-area .section-content p,
.elfooz-team-area .section-content p {
  color: #B8BDC5
}


/**
* # Footer Area Start style
* 
* ---------------------------------------------------------------- */

.footer-area {
  padding-top: 70px;
  background: #11131F;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.footer-area::after {
  --shape: 708px;
  content: "";
  width: var(--shape);
  height: var(--shape);
  border-radius: 100%;
  background-color: #662d91;
  opacity: .50;
  filter: blur(300px);
  z-index: 1;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.footer-area .site-logo img {
  max-width: 230px;
}

.footer-social-icons li {
  display: inline-block;
  margin-left: 10px;
}

.footer-menu li {
  display: inline-block;
}

.footer-menu li a {
  display: block;
  font-size: 15px;
  line-height: 180%;
  margin-left: 30px;
  color: var(--color-white);
  text-transform: capitalize;
  padding-bottom: 10px;
  transition: all .3s ease;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
}

.footer-menu li a:hover {
  opacity: .5;
  color: #fff;
}

.global-icon {
  position: relative;
  display: flex;
}

.global-icon:before {
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  height: 1px;
  background: #868D9A;
  top: 50%;
  opacity: 0.2;
}

.footer-bottom {
  padding: 30px 0;
  margin-top: 30px;
}

.footer-bottom p {
  margin: 0;
  line-height: 1.7;
  font-size: 18px;
  color: #B8BDC5;
}

.footer-bottom a {
  color: #B8BDC5;
  text-transform: capitalize;
  margin-left: 15px;
  position: relative;
  padding-left: 15px;
  transition: all .3s ease;
  font-size: 18px;
}

.footer-bottom a:hover {
  opacity: .5;
}

.footer-bottom a:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #fff;
}

.footer-bottom a:first-child::after {
  display: none;
}

.trams-and-condition-page {
  background-color: #27262B;
}

.trams-and-condition-page h1 {
  text-transform: capitalize;
}

.condition-lists li a {
  color: #333;
  margin-bottom: 10px;
  display: inline-block;
}

.condition-lists li:hover a {
  opacity: .5;
}


@media only screen and (max-width: 1920px) {

  .section-content h1,
  .section-content h2 {
    font-size: calc(35px + 54 * ((100vw - 360px) / 1920));
  }

  .section-content h1 span,
  .section-content h2 span {
    font-size: calc(28px + 29 * ((100vw - 360px) / 1920));
  }

  .sub-title {
    font-size: calc(18px + 6 * ((100vw - 360px) / 1920));
  }

  .assembled-area .section-content h2 span {
    font-size: calc(35px + 54 * ((100vw - 360px) / 1920));
  }

  .single-assembled-item h5 {
    font-size: calc(20px + 5 * ((100vw - 360px) / 1920));
  }


}

@media only screen and (max-width: 1580px) {
  .guests-from-afar-inner .section-desc {
    padding: 0 2%;
  }

  .section-content h1,
  .section-content h2 {
    font-size: 65px;
  }


}

@media only screen and (max-width: 1380px) {

  .owl-nav .owl-prev,
  .owl-nav .owl-next {
    left: -50px;
  }

  .owl-nav .owl-next {
    left: auto;
    right: -50px;
  }

  .owl-nav img {
    max-width: 40px;
  }
}

@media only screen and (min-width:360px) and (max-width: 1280px) {

  .hero-area,
  .guests-from-afar-area,
  .build-a-metaverse-area {
    height: calc(569px + 450 * ((100vw - 360px) / 1280));
  }

}

@media only screen and (max-width: 1200px) {

  .section-content h1,
  .section-content h2 {
    font-size: calc(35px + 30 * ((100vw - 360px) / 1580));
  }

  .navbar-toggler:focus,
  .navbar-toggler:active,
  .navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
  }

  .navbar-toggler,
  .navbar-toggler:focus,
  .navbar-toggler:active,
  .navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
    border: 0;
  }

  .navbar-toggler {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    padding: 0;
  }

  .navbar-toggler-icon {
    width: 35px;
    height: 3.23px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgb(255 101 47 / 20%);
    transition: all 0.5s ease-in-out;
    right: 0px;
  }

  .fixed-top .navbar-toggler-icon {
    background: #ffffff;
  }

  .fixed-top .navbar-toggler-icon::before,
  .fixed-top .navbar-toggler-icon::after {
    background: #ffffff;
  }

  .navbar-toggler-icon::before,
  .navbar-toggler-icon::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 3.23px;
    background: #fff;
    border-radius: 7px;
    box-shadow: 0 2px 5px rgb(255 101 47 / 20%);
    transition: all 0.5s ease-in-out;
    margin-left: -25px;
    right: 0px;
  }

  .navbar-toggler-icon::before {
    transform: translateY(-12px);
    width: 26px;
  }

  .navbar-toggler-icon::after {
    transform: translateY(12px);
  }

  .navbar-toggler.open .navbar-toggler-icon {
    transform: translateX(-38px);
    background: transparent;
    box-shadow: none;
  }

  .navbar-toggler.open .navbar-toggler-icon::before {
    transform: rotate(45deg) translate(35px, -35px);
  }

  .navbar-toggler.open .navbar-toggler-icon::after {
    transform: rotate(-45deg) translate(35px, 35px);
    width: 26px;
  }

  .header-area .main-menu .nav-link {
    padding: 0px 10px;
  }

  #navbar_top .site-logo h1 {
    font-size: calc(20px + 20 * ((100vw - 320px) / 1200));
    margin: 0;
  }

  .header-right {
    width: 58%;
    display: none !important;
  }

  #navbar_top.fixed-top li a {
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid #cccccc2e;
  }

  #navbar_top.fixed-top li a:hover {
    opacity: .5;
  }

  .social-icons a {
    display: inline-block !important;
    border: 0 !important;
  }

  #navbar_top .boxed-btn {
    margin: 20px 0;
    margin-right: 15px;
  }

  .site-logo img {
    max-width: 160px;
  }

  .main-menu li {
    width: 100%;
    margin-bottom: 10px;
  }

  .fixed-top .navbar ul li a {
    padding-right: 0px;
    padding-left: 20px;
  }

  .fixed-top .boxed-btn {
    min-width: 215px;
    justify-content: start;
    padding: 10px 15px;
    margin-right: 0;
  }

  .navbar-collapse.collapse.show {
    background: #11131F;
    position: relative;
  }

  .navbar-collapse.collapse.show li a {
    color: #fff;
  }

  header .navbar-collapse.collapse.show:before {
    position: absolute;
    content: "";
    left: -25%;
    top: 0;
    width: 150%;
    height: 100%;
    background: #11131F;
    z-index: -1;
  }

  .navbar_top {
    overflow-x: hidden;
  }

}

@media only screen and (min-width: 992px) and (max-width: 1280px) {
  #navbar_top .boxed-btn {
    min-width: 220px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 991px) {

  .header-area .main-menu .nav-link {
    padding: 5px 10px;
    border-bottom: 1px solid #EBE1E1;
  }

  .header-area .main-menu li:last-child .nav-link {
    border: 0;
  }

  .header-area .navbar-nav {
    margin-right: 0px;
  }

  #navbar_top .boxed-btn {
    position: relative;
  }

  .strange-new-world-area {
    padding: 100px 0;
  }

  .global-icon:before,
  .global-icon:after {
    width: 40%;
  }

  .frequently-ask-q-area {
    padding: 100px 0;
  }


}

/* min-width 992px, large screens */


@media only screen and (max-width: 767px) {
  body {
    font-size: 18px;
  }

  .hero-area-wrapper {
    height: 850px;
  }

  .section-content p a {
    font-size: 21px;
  }


  .section-desc {
    padding: 0 0%;
  }

  .footer-bottom {
    text-align: center;
  }

  .copyright {
    margin-top: 15px !important;
  }

  .faq-accordion-header button {
    font-size: 18px;
  }

  .scroll-down-btn {
    bottom: 80px;
  }

  @keyframes jumpInfinite {
    0% {
      bottom: 90px;
    }

    50% {
      bottom: 80px;
    }

    100% {
      bottom: 90px;
    }
  }

  .scroll-down-btn img {
    max-width: 60px;
  }

  .frequently-ask-q-area {
    padding: 50px 0;
  }
}

/* min-width 768px, medium screens */


@media only screen and (max-width: 576px) {

  .elfooz-team-wrapper {
    max-width: 80%;
    margin: 0 auto;
  }

  .section-content p {
    text-align: center;
  }

  .navbar_top .navbar ul li a {
    padding-right: 20px;
  }
}

/* Medium screens */

@media only screen and (max-width: 480px) {
  .container {
    width: 95%;
  }

  #navbar_top .site-logo h1 {
    display: inline-block;
    font-weight: 800;
    padding: 0 !important;
  }

  .navbar_top .boxed-btn {
    min-width: 220px;
  }


}

/* Medium screens */
